@media (max-width: 600px) {
    .Lyon .react-parallax-bgimage {
        left: 70% !important;
    }
}

@media (max-width: 340px) {
    .Lyon .react-parallax-bgimage {
        left: 100% !important;
    }

    .Grenoble .react-parallax-bgimage {
        left: 35% !important;
        display: hidden;
    }
}