* {
    margin: 0px;
    padding: 0px;
}

body, html {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box !important;
	-moz-box-sizing: border-box !important;
	-ms-box-sizing: border-box !important;
    box-sizing: border-box !important;
    cursor: default;
}

#root { 
    height: 100%;
    width: 100%;
}